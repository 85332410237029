import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {
  extractData,
  extractMeta,
  extractBlocks,
  extractMenus,
} from "../utils/data"

export const pageQuery = graphql`
  query ($alias: String!) {
    allNodeHomepage(filter: { path: { alias: { eq: $alias } } }) {
      edges {
        node {
          path {
            alias
          }
          title
          field_metatags {
            description
            title
          }
          relationships {
            field_homepage_body {
              ... on paragraph__exploring {
                paragraph_type {
                  drupal_internal__target_id
                }
                field_text_1
                field_text_2
                field_links {
                  title
                  uri
                }
              }
              ... on paragraph__quickguide {
                paragraph_type {
                  drupal_internal__target_id
                }
                relationships {
                  field_link_lists_w_image {
                    relationships {
                      field_link_list {
                        field_text_1
                        field_links {
                          title
                          uri
                        }
                      }
                      field_media_overrides {
                        field_media_image {
                          width
                          title
                          height
                          drupal_internal__target_id
                          alt
                        }
                        relationships {
                          field_media_image {
                            uri {
                              url
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on paragraph__news_placeholder {
                paragraph_type {
                  drupal_internal__target_id
                }
                field_link {
                  title
                  uri
                }
              }
              ... on paragraph__factoid_placeholder {
                paragraph_type {
                  drupal_internal__target_id
                }
                field_link {
                  title
                  uri
                }
              }
              ... on paragraph__image_links_3_ {
                paragraph_type {
                  drupal_internal__target_id
                }
                relationships {
                  field_image_links {
                    relationships {
                      field_social_icon {
                        field_url {
                          title
                          uri
                        }
                      }
                      field_media_overrides {
                        field_media_image {
                          alt
                          height
                          title
                          width
                        }
                        relationships {
                          field_media_image {
                            uri {
                              url
                              value
                            }
                          }
                        }
                      }
                    }
                    field_link {
                      title
                      uri
                    }
                    field_media_overrides {
                      overwritten_property_map
                    }
                  }
                }
              }
              ... on paragraph__homepage_social_media {
                paragraph_type {
                  drupal_internal__target_id
                }
                field_text_1
                field_link {
                  title
                  uri
                }
                relationships {
                  field_social_links {
                    field_url {
                      title
                      uri
                    }
                  }
                  field_social_image_links {
                    relationships {
                      field_image_links {
                        relationships {
                          field_social_icon {
                            field_url {
                              title
                              uri
                            }
                          }
                          field_media_overrides {
                            field_media_image {
                              alt
                              height
                              title
                              width
                            }
                            relationships {
                              field_media_image {
                                uri {
                                  url
                                  value
                                }
                              }
                            }
                          }
                        }
                        field_link {
                          title
                          uri
                        }
                        field_media_overrides {
                          overwritten_property_map
                        }
                      }
                    }
                  }
                }
              }
              ... on paragraph__homepage_hero {
                paragraph_type {
                  drupal_internal__target_id
                }
                field_text_1
                field_text_2
                relationships {
                  field_images_override {
                    field_media_image {
                      width
                      title
                      alt
                      height
                    }
                    relationships {
                      field_media_image {
                        uri {
                          url
                          value
                        }
                      }
                    }
                  }
                }
                field_images_override {
                  overwritten_property_map
                }
              }
              ... on paragraph__image_link {
                paragraph_type {
                  drupal_internal__target_id
                }
                id
                field_link {
                  title
                  uri
                }
                relationships {
                  field_social_icon {
                    field_url {
                      title
                      uri
                    }
                  }
                  field_media_overrides {
                    field_media_image {
                      alt
                      height
                      title
                      width
                    }
                    relationships {
                      field_media_image {
                        uri {
                          url
                          value
                        }
                      }
                    }
                  }
                }
                field_media_overrides {
                  overwritten_property_map
                }
              }
              ... on paragraph__link_list {
                paragraph_type {
                  drupal_internal__target_id
                }
                id
                field_text_1
                field_links {
                  title
                  uri
                }
              }
            }
            field_hero_takeover {
              field_link {
                title
                uri
              }
              relationships {
                field_image {
                  field_media_image {
                    width
                    title
                    height
                    alt
                  }
                  relationships {
                    field_media_image {
                      uri {
                        url
                        value
                      }
                    }
                  }
                }
              }
              field_bool_1
              field_text_1
              field_text_2
              text_long
            }
            field_hero {
              paragraph_type {
                drupal_internal__target_id
              }
              field_text_2
              field_text_1
              relationships {
                field_images_override {
                  relationships {
                    field_media_image {
                      uri {
                        url
                        value
                      }
                    }
                  }
                  field_media_image {
                    width
                    title
                    height
                    alt
                  }
                }
              }
              field_images_override {
                overwritten_property_map
              }
            }
          }
        }
      }
    }
    allBlockContentBasic {
      edges {
        node {
          body {
            processed
          }
          info
        }
      }
    }
    allSite {
      edges {
        node {
          siteMetadata {
            author
            description
            siteUrl
            title
          }
        }
      }
    }
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          menu_name
          enabled
          title
          url
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { data } = extractData(props)
  const { meta } = extractMeta(props)
  const { menus } = extractMenus(props)
  const { blocks } = extractBlocks(props)

  return (
    <Layout meta={meta} data={data} menus={menus} blocks={blocks}>
      <Seo meta={meta} data={data} />
    </Layout>
  )
}

export default IndexPage
